<template>
  <dashboard-layout>
    <loader-animation v-if="isLoading" />
    <div class="w-full p-5" v-if="!isLoading">
      <div class="p-4 flex">
        <h1 class="text-3xl">Incluir Candidato</h1>
      </div>
      <div class="p-4">
        <div class="flex mb-4 justify-between">
          <router-link
            class="bg-green-1000 text-white px-6 py-3 rounded rounded-md focus:outline-none"
            :to="{
              name: 'subscrible-participants',
              params: { id: this.$route.params.id }
            }"
          >
            <svg
              style="display: initial;"
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-chevron-left"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
              />
            </svg>
            Voltar
          </router-link>
        </div>
        <form
          class="w-5/12 mx-auto"
          @submit.prevent="submit"
          autocomplete="off"
        >
          <div
            class="flex mt-3 rounded-full items-center overflow-hidden shadow-md bg-white"
          >
            <input
              class="text-gray-500 placeholder-gray-500 place w-full py-3 px-5 outline-none font-normal"
              type="text"
              name="document"
              placeholder="Utilize o CPF para buscar um talento..."
              v-model="$v.document.$model"
              v-mask="'###.###.###-##'"
            />
            <button
              type="submit"
              class="text-gray-500 cursor-pointer py-3 px-3 hover:text-gray-700 focus:text-gray-700 focus:outline-none"
            >
              <svg
                class="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                />
              </svg>
            </button>
          </div>
          <div v-if="$v.document.$error">
            <div class="error" v-if="!$v.document.required">
              Campo obrigatório.
            </div>
          </div>
        </form>

        <v-client-table
          ref="table"
          :data="participants"
          :columns="columns"
          :options="options"
        >
          <div
            class="flex justify-center items-center"
            slot="resume"
            slot-scope="{ row }"
          >
            <router-link
              :to="{ name: 'curriculum', params: { id: row.id } }"
              target="_blank"
              class="mr-3 text-sm bg-yellow-500 hover:bg-yellow-700 text-white px-6 py-3 rounded focus:outline-none focus:shadow-outline"
              v-tooltip="{ content: 'Ver curriculum', placement: 'left' }"
            >
              Ver
            </router-link>
          </div>
          <div
            class="flex justify-center"
            slot="actions"
            slot-scope="{ row, index }"
            v-if="is_subscrible == 0 || is_subscrible == null"
          >
            <button
              class="bg-green-1000 text-white px-6 py-3 rounded-md focus:outline-none"
              @click="applyingToVacancy(row, index)"
              v-tooltip="{ content: 'Incluir candidato', placement: 'left' }"
            >
              Inscrever
            </button>
          </div>
          <div class="flex justify-center" v-else>
            <button
              class="bg-green-1000 text-white px-6 py-3 rounded-md focus:outline-none"
              slot="actions"
              disabled="true"
            >
              Inscrito
            </button>
          </div>
        </v-client-table>
      </div>

      <div
        class="w-12/12 border border-green-600 rounded-lg py-3 px-5 mx-auto mt-5"
      >
        <div class="p-4 flex">
          <h1 class="text-lg">Candidatos pré Selecionados</h1>
        </div>
        <v-client-table
          ref="table"
          :data="pre_selected_participants"
          :columns="columns"
          :options="options"
        >
          <div
            class="flex justify-center items-center"
            slot="resume"
            slot-scope="{ row }"
          >
            <router-link
              class="flex p-1 border-2 w-max rounded-lg mr-4 border-yellow-500 hover:bg-yellow-500 text-yellow-500 focus:outline-none focus:shadow-outline has-tooltip"
              :to="{
                name: 'curriculum',
                params: { id: row.participant_id }
              }"
              v-tooltip="{ content: 'Ver Currículo', placement: 'left' }"
              target="_blank"
            >
              <i
                class="ri-article-line py-0 px-2 text-2xl hover:text-green-50"
              ></i>
            </router-link>
          </div>
          <div class="flex justify-center" slot="actions" slot-scope="{ row }">
            <input
              type="checkbox"
              v-model="selectedParticipants"
              :value="row.user_id"
              v-tooltip="{
                content: 'Selecionar Participantes',
                placement: 'right'
              }"
            />
          </div>
        </v-client-table>
        <div class="flex my-4 justify-end">
          <button
            class="bg-green-1000 text-white px-8 py-3 rounded-full focus:outline-none"
            @click="addSuggestions"
          >
            Salvar
          </button>
        </div>
      </div>
    </div>
  </dashboard-layout>
</template>

<script>
import DashboardLayout from '../../../layouts/DashboardLayout';
import axios from '@/utils/axios';
import { mask } from 'vue-the-mask';
import { required } from 'vuelidate/lib/validators';
import LoaderAnimation from '../../../components/LoaderAnimation.vue';

export default {
  name: 'ApplyingParticipantAccess',

  title() {
    return `${process.env.VUE_APP_NAME} | Incluir Candidatos`;
  },

  components: {
    DashboardLayout,
    LoaderAnimation
  },

  directives: { mask },

  data() {
    return {
      isLoading: true,
      document: '',
      is_subscrible: 0,
      participants: [],
      pre_selected_participants: [],
      selectedParticipants: [],
      copySelectedParticipants: [],
      columns: ['id', 'name', 'document', 'email', 'resume', 'actions'],
      options: {
        sortable: true,
        filterable: false,
        perPage: 10,
        perPageValues: [10, 15, 20, 25, 50],
        headings: {
          name: 'Nome',
          document: 'CPF',
          email: 'E-mail',
          resume: 'Currículo',
          actions: 'Ações'
        },
        hiddenColumns: ['id'],
        sortIcon: {
          is: 'glyphicon-sort',
          base: 'glyphicon',
          up: 'glyphicon-chevron-up',
          down: 'glyphicon-chevron-down'
        },
        texts: {
          first: 'Primeiro',
          last: 'Último',
          filter: 'Filtrar:',
          filterBy: 'Filtrar por {column}',
          filterPlaceholder: 'Pesquise aqui...',
          count:
            '{from} - {to} de {count} registros|{count} registros|Um registro',
          limit: 'Registros por página:',
          page: 'Página:',
          noResults: 'Nenhum candidato encontrado.',
          columns: 'Colunas'
        },
        pagination: {
          chunk: 5
        }
      }
    };
  },

  validations: {
    document: {
      required
    }
  },

  methods: {
    submit() {
      if (this.$v.$invalid) {
        this.$v.document.$touch();
        this.isLoading = false;
      } else {
        axios
          .post('/api/participantes/buscar', {
            document: this.document
          })
          .then(({ data }) => {
            this.participants.push(data.data.data);
            this.$toast.success(data.data.message);
            this.isLoading = false;
            this.alreadySubscrible();
          })
          .catch(({ response }) => {
            Object.values(response.data.errors).forEach(error => {
              this.$toast.error(...error);
              this.isLoading = false;
            });
          });
      }
    },

    applyingToVacancy() {
      this.$confirm({
        message: 'Deseja realmente inscrever esse talento?',
        button: {
          no: 'Não',
          yes: 'Sim'
        },
        callback: confirm => {
          if (confirm) {
            axios.post('/api/vagas/inscricao', {
              participant: this.participants[0].id,
              vacancy: this.$route.params.id
            });

            this.$toast.success('Talento inscrito com sucesso!');
            // this.$router.push({ name: 'subscrible-participants' }).go();
            this.$router.go(-1);
          }
        }
      });
    },

    alreadySubscrible() {
      axios
        .post('/api/vagas/inscricao-participante', {
          participant: this.participants[0].id,
          vacancy: this.$route.params.id
        })
        .then(({ data }) => {
          this.is_subscrible = data;
        });
    },

    talentPreSelected() {
      axios
        .get(`api/vagas/talent-by-keyword/${this.$route.params.id}`)
        .then(({ data }) => {
          console.log(data);
          data.data.suggestion.forEach(participants => {
            this.pre_selected_participants.push(participants);
          });

          data.data.selected.forEach(selecteds => {
            this.selectedParticipants.push(selecteds.user_id);
            this.copySelectedParticipants = this.selectedParticipants;
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    addSuggestions() {
      this.$confirm({
        message: 'Tem certeza que deseja confirmar as sugestões?',
        button: {
          no: 'Não',
          yes: 'Sim'
        },
        callback: confirm => {
          if (confirm) {
            axios
              .put(
                `api/vagas/update-notification-vacancy/${this.$route.params.id}`,
                {
                  selecteds: this.selectedParticipants,
                  defaultSelecteds: this.copySelectedParticipants
                }
              )
              .then(({ data }) => {
                this.$toast.success(data.data.message);

                this.$router.go(0);
              })
              .catch(({ response }) => {
                this.$toast.error(
                  'Não foi possivel salvar as novas sugestões.'
                );
                Object.values(response.data.data.errors).forEach(error => {
                  this.$toast.error(...error);
                });
              });
          }
        }
      });
    }
  },

  created: function() {
    this.talentPreSelected();
  }
};
</script>

<style scoped></style>
